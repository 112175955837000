'use client';

import { cn } from '@/utils/helper';
import Link from 'next/link';
import { FaHeart } from 'react-icons/fa';

type BannerButtonProps = {
  btnColor?: string;
};

const BannerButton = ({ btnColor = 'yellow' }: BannerButtonProps) => {
  return (
    <Link
      href='/user/sign-in'
      className={cn(
        'animate-pulse btn btn-banner flex items-center gap-4',
        'px-4 py-1 md:px-8 md:py-2 lg:px-12 lg:py-3',
        `btn-banner-${btnColor}`
      )}
    >
      <FaHeart className='shadow-inner hidden md:block' size={36} />
      <FaHeart className='shadow-inner block md:hidden' size={20} />
      <div className='text-[1.5em] md:text-[3em] whitespace-nowrap leading-loose'>
        Get Started
      </div>
    </Link>
  );
};

export default BannerButton;
