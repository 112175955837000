'use client';

import { RotatingLines } from 'react-loader-spinner';

type Props = {
  size?: string;
  strokeColor?: string;
};

export default function Loading({
  size = '96',
  strokeColor = '#666666',
}: Props) {
  return (
    <div className='fixed inset-0 flex items-center justify-center'>
      <RotatingLines
        visible={true}
        strokeColor={strokeColor}
        width={size}
        strokeWidth='1'
        ariaLabel='rotating-lines-loading'
      />
    </div>
  );
}
