'use client';

import { trpc } from '@/app/api/trpc/client';
import { ThemeProvider } from '@/app/components/Theme';
import { ClerkProvider } from '@clerk/nextjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import posthog from 'posthog-js';
import { ReactNode, useEffect, useState } from 'react';
import superjson from 'superjson';

export function Providers({ children }: { children: ReactNode }) {
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: '/api/trpc',
          transformer: superjson,
        }),
      ],
    })
  );

  const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
  const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST;
  useEffect(() => {
    if (posthogKey && posthogHost) {
      posthog.init(posthogKey, {
        // eslint-disable-next-line no-undef
        api_host: posthogHost,
        person_profiles: 'identified_only',
        debug: false,
      });
    }
  }, []);

  const queryClient = new QueryClient();
  // eslint-disable-next-line no-undef
  const publishableKey = process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY;

  return (
    <ClerkProvider
      publishableKey={publishableKey}
      signUpUrl='/user/sign-up'
      signInUrl='/user/sign-in'
      waitlistUrl='/user/waitlist'
    >
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>{children}</ThemeProvider>
        </QueryClientProvider>
      </trpc.Provider>
    </ClerkProvider>
  );
}
