export const theme = {
  fontFamily: {
    sans: ['Roboto', 'Inter', 'sans-serif'],
  },
  typography: {
    DEFAULT: {
      css: {
        '--tw-prose-body': 'var(--tw-prose-body)',
      },
    },
  },
  extend: {
    backgroundImage: {
      'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
      'gradient-conic':
        'conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))',
    },
    height: {
      96: '24rem',
      104: '26rem',
      112: '28rem',
      128: '32rem',
      144: '36rem',
      160: '40rem',
    },
    width: {
      112: '28rem',
      128: '32rem',
      144: '36rem',
      160: '40rem',
      176: '44rem', //laptop full height.
    },
    fontSize: {
      '2xs': '0.625rem',
      '3xs': '0.5rem',
      '2xl': '1.2rem',
      '3xl': '1.5rem',
      '4xl': '2.5rem',
      '5xl': '3rem',
      '6xl': '3.5rem',
    },
    fontFamily: {
      handwriting: ['Chalkboard SE', 'cursive'], // Human writing style
      decorative: ['Dancing Script', 'Pacifico', 'cursive'], // Decorative writing style
      playful: ['Comic Sans MS', 'Comic Neue', 'cursive'], // Playful writing style
      monospace: ['monospace', 'IBM Plex Mono', 'Fira Code'], // AI / Monospaced
      banner: ['"Lobster"', 'sans-serif'],
    },
    letterSpacing: {
      'widest-2': '0.8rem',
    },
    colors: {
      'zone-1': 'rgb(42, 97, 124)',
      'zone-2': 'rgb(130, 202, 157)',
      'zone-3': 'rgb(255, 198, 88)',
      'zone-4': 'rgb(255, 115, 0)',
      'zone-5': 'rgb(231, 33, 33)',
      'zone-6': 'rgb(194, 47, 197)',
      'highlight': 'rgb(253, 230, 138)',
    },
    screens: {
      '2xl': '1536px',
      '3xl': '1920px',
      '4xl': '2560px',
      '5xl': '3840px',
    },
  },
  container: {
    padding: {
      'DEFAULT': '1rem',
      'sm': '2rem',
      'lg': '4rem',
      'xl': '5rem',
      '2xl': '6rem',
      '3xl': '8rem',
      '4xl': '10rem',
      '5xl': '12rem',
    },
    margin: {
      'sm': '2rem',
      'lg': '4rem',
      'xl': '5rem',
      '2xl': '6rem',
      '3xl': '8rem',
      '4xl': '10rem',
      '5xl': '12rem',
    },
  },
};
