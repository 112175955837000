'use client';

import { SignInButton, UserButton } from '@clerk/nextjs';
import {
  FaBars,
  FaComments,
  FaDumbbell,
  FaGear,
  FaMoon,
  FaSun,
  FaUser,
} from 'react-icons/fa6';

import { trpc } from '@/app/api/trpc/client';
import { useCalendarState } from '@/app/calendar/useCalendarState';
import { useTheme } from '@/app/components/Theme';
import { cn, landingPageUrl } from '@/utils/helper';
import { useClerk, useUser } from '@clerk/clerk-react';
import { format } from 'date-fns';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import { BsCalendar3 } from 'react-icons/bs';
import { FcSynchronize } from 'react-icons/fc';
import { RxActivityLog } from 'react-icons/rx';
import { ToastContainer } from 'react-toastify';

const Header = () => {
  const { mutate: syncStrava, isPending } =
    trpc.strava.syncStrava.useMutation();
  const { isSignedIn, user } = useUser();
  const { signOut } = useClerk();
  const { isDarkMode, toggleDarkMode } = useTheme();
  const { showChatWindow, setShowChatWindow } = useCalendarState();

  const navItemsAll = [
    {
      id: 'coachModal',
      href: '#',
      icon: <FaUser className='icon' />,
      onClick: () => setShowChatWindow(!showChatWindow),
      label: 'Coach',
      public: false,
    },
    {
      id: 'coach',
      href: '/coach',
      icon: <FaUser className='icon' />,
      label: 'Coach',
      public: false,
    },
    {
      id: 'stravaSync',
      href: '#',
      onClick: () => syncStrava({ fromDaysAgo: 0 }),
      icon: (
        <FcSynchronize
          className={cn([
            isPending ? 'icon loading-icon' : 'icon',
            'w-5 h-5 cursor-pointer',
          ])}
        />
      ),
      label: 'Sync Latest',
      public: false,
    },
    {
      id: 'calendar',
      href: landingPageUrl,
      icon: <BsCalendar3 className='icon' />,
      label: 'Calendar',
      public: false,
    },
    {
      id: 'activities',
      href: `/activities/${format(new Date(), 'yyyy-MM-dd')}`,
      icon: <RxActivityLog className='icon' />,
      label: 'Activities',
      public: false,
    },
    {
      id: 'workouts',
      href: '/workouts',
      icon: <FaDumbbell className='icon' />,
      label: 'Workouts',
      public: false,
    },
    {
      id: 'forum',
      href: '/forum',
      icon: <FaComments className='icon' />,
      label: 'Forum',
      public: false,
    },
    {
      id: 'settings',
      href: '/settings',
      icon: <FaGear className='icon' />,
      label: 'Settings',
      public: false,
    },
  ];

  const activeItems = ['coach', 'calendar', 'forum', 'settings'];
  const navItems = navItemsAll.filter((item) => activeItems.includes(item.id));
  // eslint-disable-next-line no-undef
  const gitCommit = process.env.NEXT_PUBLIC_GIT_COMMIT ?? 'TrainMe';
  const landingPage = isSignedIn ? landingPageUrl : '/';
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header className='flex justify-between items-center fixed h-12 px-4 bg-gray-400 dark:bg-gray-900 text-gray-900 dark:text-gray-100 p-1 top-0 left-0 right-0 z-50'>
      <FaBars
        className='icon cursor-pointer block md:hidden mr-24'
        size={24}
        onClick={() => {
          setIsMobileMenuOpen(!isMobileMenuOpen);
        }}
      />
      <ul
        id='mobile-menu'
        className={cn(
          'block md:hidden absolute top-12 p-4 right-0 bg-gray-200 dark:bg-gray-800 w-screen text-[1.5em] leading-[1.5em]',
          isMobileMenuOpen ? 'block' : 'hidden'
        )}
      >
        {navItems.map(
          (item, index) =>
            (item.public || isSignedIn) && (
              <li key={index} className='w-full'>
                <a
                  href={item.href}
                  onClick={(e) => {
                    e.preventDefault();
                    item.onClick?.();
                    setIsMobileMenuOpen(false);
                  }}
                  className='hover:underline w-full flex justify-start gap-4 items-center btn btn-link'
                >
                  {item.icon}
                  <span className='text-[1.2em]'>{item.label}</span>
                </a>
              </li>
            )
        )}
        <hr className='border-t border-gray-400 dark:border-gray-600 w-full my-4' />
        <li className='w-full flex justify-start'>
          <button
            onClick={toggleDarkMode}
            className='hover:underline flex gap-2 items-center btn btn-link'
          >
            {isDarkMode ? (
              <FaSun
                className='icon text-yellow-300 hover:text-yellow-200'
                size={24}
              />
            ) : (
              <FaMoon
                className='icon text-blue-700 hover:text-blue-800'
                size={24}
              />
            )}
            <span>{isDarkMode ? 'Light Mode' : 'Dark Mode'}</span>
          </button>
        </li>
        <li className='w-full flex justify-start'>
          {isSignedIn ? (
            <button
              className='flex gap-2 items-center btn btn-link mx-0'
              onClick={() => signOut()}
            >
              <Image
                src={user.imageUrl}
                className='avatar rounded-full'
                alt='User'
                width={28}
                height={28}
              />
              Sign Out
            </button>
          ) : (
            <SignInButton mode='modal'>
              <Link
                href='/user/sign-in'
                className='btn btn-link flex gap-4 items-center'
              >
                <FaUser className='icon' size={24} />
                <span>Sign In</span>
              </Link>
            </SignInButton>
          )}
        </li>
      </ul>
      <a href={landingPage} className='font-normal flex items-center'>
        <div className='bg-emerald-600 rounded-full w-8 h-8' title={gitCommit}>
          <Image src='/trainme.webp' alt='Logo' width={48} height={48} />
        </div>
        <h1 className='text-blue-100 h1'>TrainMe</h1>
      </a>
      <ToastContainer
        theme={isDarkMode ? 'dark' : 'light'}
        autoClose={7 * 1000}
        position='bottom-left'
        hideProgressBar={false}
        closeOnClick={false}
        pauseOnHover={false}
        draggable={true}
        pauseOnFocusLoss={false}
        className='toast-container'
        key={isDarkMode ? 'dark' : 'light'}
      />
      <nav className='flex justify-between items-center'>
        {isSignedIn && (
          <ul
            id='desktop-menu'
            className='hidden md:flex gap-2 justify-between items-center'
          >
            {navItems.map(
              (item, index) =>
                (item.public || isSignedIn) && (
                  <li key={index}>
                    <a
                      href={item.href}
                      onClick={item.onClick}
                      className='hover:underline flex gap-2 items-center btn btn-link'
                    >
                      {item.icon}
                      <span className=''>{item.label}</span>
                    </a>
                  </li>
                )
            )}
            <li>
              <button
                onClick={toggleDarkMode}
                className='hover:underline flex gap-2 items-center btn btn-link'
              >
                {isDarkMode ? (
                  <FaSun className='icon text-yellow-300 hover:text-yellow-200' />
                ) : (
                  <FaMoon className='icon text-blue-700 hover:text-blue-800' />
                )}
              </button>
            </li>
          </ul>
        )}
        {isSignedIn ? (
          <UserButton
            appearance={{
              elements: {
                avatarBox: 'h-8 w-8',
              },
            }}
          />
        ) : (
          <SignInButton mode='redirect'>
            <button className='btn btn-link'>
              <FaUser className='icon' />
              <span className='text-[0.9em]'>Sign In/Up</span>
            </button>
          </SignInButton>
        )}
      </nav>
    </header>
  );
};

export default Header;
