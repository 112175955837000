/* eslint-disable no-unused-vars */
'use client';
import { defaultWorkout } from '@/utils/helper';
import { ActivityWithRelations, WorkoutWithRelations } from '@/utils/types';
import { Analysis } from '@trainme/db';
import { create } from 'zustand';

interface CalendarState {
  activity: ActivityWithRelations | null;
  setActivity: (activity: ActivityWithRelations | null) => void;
  activities: ActivityWithRelations[];
  setActivities: (activities: ActivityWithRelations[]) => void;
  workout: WorkoutWithRelations | null;
  setWorkout: (workout: WorkoutWithRelations | null) => void;
  workouts: WorkoutWithRelations[];
  setWorkouts: (workouts: WorkoutWithRelations[]) => void;
  analyses: Analysis[];
  setAnalyses: (analyses: Analysis[]) => void;
  scheduleDate: Date;
  setScheduleDate: (date: Date) => void;
  showChatWindow: boolean;
  setShowChatWindow: (show: boolean) => void;
}

export const useCalendarState = create<CalendarState>((set) => ({
  activity: null,
  setActivity: (activity) => set({ activity }),
  activities: [],
  setActivities: (activities) => set({ activities }),
  workout: { ...defaultWorkout, userId: '', activityId: null },
  setWorkout: (workout) => set({ workout }),
  workouts: [],
  setWorkouts: (workouts) => set({ workouts }),
  analyses: [],
  setAnalyses: (analyses) => set({ analyses }),
  scheduleDate: new Date(),
  setScheduleDate: (date) => set({ scheduleDate: date }),
  showChatWindow: false,
  setShowChatWindow: (show) => set({ showChatWindow: show }),
}));
