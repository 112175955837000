import type {
  Activity,
  Analysis,
  ForumCategory,
  ForumComment,
  ForumPost,
  ForumUser,
  GarminWorkout,
  StravaLap,
  User,
  Workout,
} from '@trainme/db';

export type MapField = {
  id: string;
  polyline?: string;
  resourceState: number;
  summaryPolyline?: string;
};

export type ActivityWithRelations = Activity & { laps?: StravaLap[] };
export type WorkoutWithRelations = Workout & {
  analysis?: Analysis;
  garminWorkout?: GarminWorkout;
};
export type ForumPostWithRelations = ForumPost & {
  author: ForumUser & { user: User };
  comments: ForumCommentWithRelations[];
};
export type ForumCommentWithRelations = ForumComment & {
  author: ForumUser & { user: User };
};
export type ForumCategoryWithRelations = ForumCategory & {
  posts: ForumPostWithRelations[];
};

// The calendar view, lowercase to match the URL params
export enum View {
  Week = 'week',
  Month = 'month',
  Day = 'day',
}
