import { cn } from '@/utils/helper';

interface FooterProps {
  className?: string;
}
export default function Footer({ className }: FooterProps) {
  const links = [
    {
      title: 'Customer Support',
      description: 'Receive assistance with your account or any issues.',
      url: '/customer/support',
    },
    {
      title: 'Contact Us',
      description: 'Reach out to us with any questions or feedback.',
      url: '/customer/contact',
    },
    {
      title: 'Terms of Service',
      description:
        'Read our terms of service to understand how we manage your data.',
      url: '/customer/terms',
    },
    {
      title: 'Privacy Policy',
      description:
        'Review our privacy policy to understand how we manage your data.',
      url: '/customer/privacy',
    },
    {
      title: 'Pricing',
      description: 'Pricing plans.',
      url: '/pricing',
    },
  ];

  return (
    <footer
      className={cn([
        'fixed bottom-0 w-full',
        'bg-gray-400 dark:bg-gray-700 text-white p-4',
        className,
      ])}
    >
      <div className='container mx-auto max-w-screen-2xl flex flex-wrap items-center justify-between gap-4'>
        {links.map((link, index) => (
          <div key={index} className='whitespace-nowrap'>
            <a
              href={link.url}
              className='hover:underline'
              title={link.description}
            >
              {link.title}
            </a>
          </div>
        ))}
        &copy; Naozz@{new Date().getFullYear()}. All rights reserved.
      </div>
    </footer>
  );
}
