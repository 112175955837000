import { theme } from '@/app/theme/theme';
import { Prisma, TrainingZone, Workout } from '@trainme/db';
import { clsx, type ClassValue } from 'clsx';
import { format } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import { View } from './types';

export const getZoneColor = (zone: number = 1): string => {
  const zoneKey = `zone-${zone}` as keyof typeof theme.extend.colors;
  const colorValue = theme.extend.colors[zoneKey];
  return colorValue;
};

export const getZoneFromHeartRate = (
  heartRate: number,
  zones: TrainingZone[]
): number => {
  const zone = zones.find(
    (zone) => heartRate >= zone.minValue && heartRate <= zone.maxValue
  );
  return zone?.zone || 1;
};

// not very useful, I'd do `h-full` + ${className} instead.
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getCalendarViewFromPathname(pathname: string) {
  const pathParts = pathname.split('/');
  if (pathParts[1] === 'calendar') {
    return pathParts[2] as View;
  }
  return View.Week;
}

export const defaultWorkout: Workout = {
  id: '',
  name: 'TrainMe Workout',
  date: new Date(),
  description: 'Base run. Created by Trainme.',
  sportType: 'Run',
  distance: 5000,
  duration: 1800,
  steps: ['5m Z2 HR Warm up', '20m Z3 HR active', '5m Z1 HR cool down'],
  feeling: null,
  rpe: null,
  notes: null,
  userId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  garminWorkoutId: null,
  garminScheduleId: null,
};

export const workoutStepsInputPlaceholder = `
Example steps:

-----------------------------
5m Z2 HR Warm up
20m Z3 HR active
5m Z1 HR cool down
-----------------------------

Each line is a step. '5m' means 5 minutes, 'Z1' means zone one.`;

export const emptyWorkout: Workout = {
  id: '',
  name: '',
  date: new Date(),
  description: '',
  sportType: 'Run',
  distance: null,
  duration: null,
  steps: [],
  feeling: null,
  rpe: null,
  notes: null,
  userId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  garminWorkoutId: null,
  garminScheduleId: null,
};

export const defaultSettings: Prisma.TrainingSettingCreateInput = {
  maxHeartRate: 180,
  restHeartRate: 60,
  ftp: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  sportsTypes: ['Run'],
  user: {
    connect: {
      id: '',
    },
  },
};

export const landingPageUrl = `/calendar/month/${format(new Date(), 'yyyy-MM-dd')}`;
